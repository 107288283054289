import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Card,
  Tooltip,
  Modal,
  Form,
  Input,
  Checkbox,
  Button,
  Typography,
} from "antd";
import { getReq, postReq } from "../api";
import Swal from "sweetalert2";
import { toastNotification } from "../utils/toasthelper";
const { Title, Text } = Typography;

const Roles = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [form] = Form.useForm();
  const userRole = localStorage.getItem("userRole");
  useEffect(() => {
    // Reset the form fields when the modal opens for editing a user
    if (showModal) {
      form.resetFields(); // Clear the form on modal close
    }
  }, [showModal, form]);
  const [columns, setColumns] = useState([
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Permissions",
      key: "permissions",
      dataIndex: "permissions",
      render: (_, { permissions }) => {
        const displayedPermissions = permissions.slice(0, 4);
        const remainingPermission = permissions.slice(4);
        const remainingCount = permissions.length - 4;

        return (
          <>
            {displayedPermissions.map((tag) => (
              <span
                key={tag}
                style={{ display: "inline-block", marginRight: 6 }}
              >
                <p>{tag.toUpperCase()},</p>
              </span>
            ))}
            {remainingCount > 0 && (
              <Tooltip
                placement="top"
                title={
                  <div
                    style={{
                      gap: "4px",
                      maxWidth: "200px",
                      wordWrap: "break-word",
                    }}
                  >
                    {remainingPermission.map((tag) => (
                      <span key={tag} style={{ whiteSpace: "nowrap" }}>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                          {tag.toUpperCase()}
                        </p>
                      </span>
                    ))}
                  </div>
                }
              >
                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 4,
                    height: "20px",
                    width: "40px",
                    borderRadius: "10px",
                    background: "#F8F8F8",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  <p
                    style={{ margin: 0, fontWeight: "bold", cursor: "pointer" }}
                  >
                    +{remainingCount}
                  </p>
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div style={{ cursor: "pointer" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleEditClick(record)}
            >
              <path
                d="M16.625 13.608L11.6667 18.5663C11.2315 19.0003 10.6307 19.25 10.017 19.25H9.33333V18.5663C9.33333 17.9433 9.576 17.3577 10.017 16.9167L14.9753 11.9583L16.625 13.608ZM17.8418 9.09183L16.625 10.3087L18.2747 11.9583L19.4915 10.7415C19.9465 10.2865 19.9465 9.54683 19.4915 9.09183C19.0505 8.64967 18.2817 8.64967 17.8418 9.09183ZM28 5.83333V22.1667C28 25.3832 25.3832 28 22.1667 28H5.83333C2.61683 28 0 25.3832 0 22.1667V5.83333C0 2.61683 2.61683 0 5.83333 0H22.1667C25.3832 0 28 2.61683 28 5.83333ZM21.1423 7.44217C19.8193 6.12033 17.514 6.12033 16.1922 7.44217L8.36733 15.267C7.4865 16.1478 7 17.3203 7 18.5663V20.4167C7 21.0618 7.52267 21.5833 8.16667 21.5833H10.017C11.263 21.5833 12.4355 21.098 13.3163 20.216L21.1412 12.3912C22.5062 11.0262 22.5073 8.80717 21.1423 7.44217Z"
                fill="#666666"
              />
            </svg>
          </div>
          <div style={{ cursor: "pointer" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => deleteRole(record._id)}
            >
              <path
                d="M9.36304 20.4881C9.36304 20.5674 9.39448 20.6434 9.45046 20.6995C9.50644 20.7555 9.58239 20.7871 9.66163 20.7873H18.3383C18.4176 20.7871 18.4935 20.7555 18.5495 20.6995C18.6055 20.6434 18.6369 20.5674 18.6369 20.4881V10.6952H9.36304V20.4881ZM14.7913 12.8576C14.788 12.7051 14.8151 12.5534 14.8712 12.4115C14.9273 12.2696 15.0111 12.1404 15.1178 12.0313C15.2245 11.9223 15.3519 11.8356 15.4925 11.7765C15.6332 11.7173 15.7842 11.6868 15.9367 11.6868C16.0893 11.6868 16.2403 11.7173 16.381 11.7765C16.5216 11.8356 16.649 11.9223 16.7557 12.0313C16.8624 12.1404 16.9462 12.2696 17.0023 12.4115C17.0583 12.5534 17.0855 12.7051 17.0822 12.8576V18.4455C17.0855 18.5981 17.0583 18.7497 17.0023 18.8916C16.9462 19.0335 16.8624 19.1628 16.7557 19.2718C16.649 19.3809 16.5216 19.4675 16.381 19.5267C16.2403 19.5858 16.0893 19.6163 15.9367 19.6163C15.7842 19.6163 15.6332 19.5858 15.4925 19.5267C15.3519 19.4675 15.2245 19.3809 15.1178 19.2718C15.0111 19.1628 14.9273 19.0335 14.8712 18.8916C14.8151 18.7497 14.788 18.5981 14.7913 18.4455V12.8576ZM10.9178 12.8576C10.9145 12.7051 10.9416 12.5534 10.9977 12.4115C11.0538 12.2696 11.1376 12.1404 11.2443 12.0313C11.351 11.9223 11.4784 11.8356 11.619 11.7765C11.7596 11.7173 11.9107 11.6868 12.0632 11.6868C12.2158 11.6868 12.3668 11.7173 12.5074 11.7765C12.6481 11.8356 12.7755 11.9223 12.8822 12.0313C12.9889 12.1404 13.0727 12.2696 13.1288 12.4115C13.1848 12.5534 13.212 12.7051 13.2087 12.8576V18.4455C13.212 18.5981 13.1848 18.7497 13.1288 18.8916C13.0727 19.0335 12.9889 19.1628 12.8822 19.2718C12.7755 19.3809 12.6481 19.4675 12.5074 19.5267C12.3668 19.5858 12.2158 19.6163 12.0632 19.6163C11.9107 19.6163 11.7596 19.5858 11.619 19.5267C11.4784 19.4675 11.351 19.3809 11.2443 19.2718C11.1376 19.1628 11.0538 19.0335 10.9977 18.8916C10.9416 18.7497 10.9145 18.5981 10.9178 18.4455V12.8576Z"
                fill="#F44336"
              />
              <path
                d="M12.6564 7.21274H15.3432V8.40438H12.6564V7.21274Z"
                fill="#F44336"
              />
              <path
                d="M21.4003 0H6.59969C4.84934 0 3.17069 0.695322 1.933 1.933C0.695322 3.17069 0 4.84934 0 6.59969L0 21.4003C0 23.1507 0.695322 24.8293 1.933 26.067C3.17069 27.3047 4.84934 28 6.59969 28H21.4003C23.1507 28 24.8293 27.3047 26.067 26.067C27.3047 24.8293 28 23.1507 28 21.4003V6.59969C28 4.84934 27.3047 3.17069 26.067 1.933C24.8293 0.695322 23.1507 0 21.4003 0ZM21.537 10.6952H20.9278V20.4881C20.9272 21.1748 20.6542 21.8331 20.1688 22.3187C19.6833 22.8043 19.025 23.0774 18.3384 23.0781H9.66164C8.975 23.0774 8.3167 22.8043 7.83123 22.3187C7.34575 21.8331 7.07277 21.1748 7.07219 20.4881V10.6952H6.46297C6.31044 10.6986 6.15879 10.6714 6.0169 10.6153C5.87501 10.5593 5.74575 10.4754 5.6367 10.3687C5.52765 10.262 5.44101 10.1346 5.38186 9.99402C5.32271 9.85339 5.29224 9.70237 5.29224 9.5498C5.29224 9.39724 5.32271 9.24622 5.38186 9.10559C5.44101 8.96496 5.52765 8.83756 5.6367 8.73087C5.74575 8.62418 5.87501 8.54034 6.0169 8.48427C6.15879 8.42821 6.31044 8.40104 6.46297 8.40438H10.3655V6.07031C10.3655 5.76573 10.4865 5.47362 10.7018 5.25824C10.9172 5.04287 11.2093 4.92188 11.5139 4.92188H16.4905C16.7946 4.9226 17.086 5.04342 17.3011 5.25824C17.5162 5.47306 17.6372 5.76573 17.6372 6.07031V8.40438H21.5397C21.6922 8.40104 21.8439 8.42821 21.9857 8.48427C22.1276 8.54034 22.2569 8.62418 22.3659 8.73087C22.4749 8.83756 22.5616 8.96496 22.6208 9.10559C22.6799 9.24622 22.7104 9.39724 22.7104 9.5498C22.7104 9.70237 22.6799 9.85339 22.6208 9.99402C22.5616 10.1346 22.4749 10.262 22.3659 10.3687C22.2569 10.4754 22.1276 10.5593 21.9857 10.6153C21.8439 10.6714 21.6922 10.6986 21.5397 10.6952H21.537Z"
                fill="#F44336"
              />
            </svg>
          </div>
        </Space>
      ),
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    // page: 1,
    // limit: 10,
  });
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const permissions = [
    "view_dashboard",
    "view_requirements",
    "view_responses",
    "view_vendors",
    "view_technology_niche",
    "edit_timesheet",
    "view_jobdetails",
    "view_candidates",
    "view_compliance_documents",
    "add_jobdetails",
    "view_edit_requirement",
    "view_invoices",
    "view_timesheet",
  ];
  useEffect(() => {
    if (editData.name) {
      form.setFieldsValue({ name: editData.name });
    }
  }, [editData, form]);
  const handleEditClick = (record) => {
    setEditData(record);
    setCheckedPermissions(record.permissions);
    form.setFieldsValue({ name: record.name });
    setShowModal(true);
  };

  const handleCheck = (permission) => {
    const updatedPermissions = checkedPermissions.includes(permission)
      ? checkedPermissions.filter((perm) => perm !== permission)
      : [...checkedPermissions, permission];
    setCheckedPermissions(updatedPermissions);
    setEditData((prevData) => ({
      ...prevData,
      permissions: updatedPermissions,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const updateRole = await postReq("/company/update-role", {
        _id: editData._id,
        name: values.name,
        permissions: checkedPermissions,
      });
      if (updateRole.status == 1) {
        toastNotification("success", updateRole.message);
        await fetchData();
        setTimeout(() => {
          setEditData({});
          fetchData();
          handleCancel();
        }, 2000);
        setLoading(false);
      } else {
        toastNotification("error", updateRole.message);
        setTimeout(() => {
          setEditData({});
          handleCancel();
        }, 2000);
        setLoading(false);
      }
    } catch (error) {
      setEditData({});
      setLoading(false);
    }
    setShowModal(false);
  };
  const handleAddSubmit = async (values) => {
    try {
      setLoading(true);
      const addRole = await postReq("/company/create-role", {
        name: values.role,
        permissions: checkedPermissions,
      });
      if (addRole.status == 1) {
        toastNotification("success", addRole.message);
        await fetchData();
        setTimeout(() => {
          form.setFieldsValue({});
          setShowAddModal(false);
          setLoading(false);
        }, 1000);
      } else {
        toastNotification("error", addRole.message);
        setTimeout(() => {
          form.setFieldsValue({});
          setShowAddModal(false);
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
    setShowModal(false);
  };
  const deleteRole = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const deleteRoleApi = await await postReq("/company/delete-role", {
          _id: id,
        });

        if (deleteRoleApi.status === 1) {
          toastNotification("success", "The role has been deleted.");
          fetchData();
        } else {
          toastNotification("error", deleteRoleApi.message);
          fetchData();
        }
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let results = {};
    if (userRole == "company") {
      results = await postReq("/company/get-roles-list", tableParams);
    } else if (userRole !== "vendor" || userRole !== "candidate") {
      results = await postReq("/common/get-roles-list-user", tableParams);
    }
    setData(results.data.roles);
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: results.data.totalCount,
      },
    }));
    // setTableParams({
    //   ...tableParams,
    //   pagination: {
    //     total: results.data.totalCount,
    //   },
    // });
    setLoading(false);
  };

  const handleCancel = async () => {
    setEditData({});
    setShowModal(!showModal);
  };
  const handleAddCancel = async () => {
    setShowAddModal(!showAddModal);
  };

  useEffect(fetchData, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    }));
    // setTableParams({
    //   pagination,
    //   filters,
    //   sortOrder: sorter.order,
    //   sortField: sorter.field,
    // });
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };
  const resetCheckboxes = () => {
    setCheckedPermissions([]); // Clear selected permissions
    setEditData((prevData) => ({
      ...prevData,
      permissions: [],
    }));
  };

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Update Role
            </Title>
            <svg
              width="24"
              height="24"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={handleCancel}
            >
              <circle cx="19" cy="19" r="19" fill="#F4F4F4" />
              <path
                d="M13.4854 13.4852L25.0001 25"
                stroke="#333"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M25 13.4852L13.4853 25"
                stroke="#333"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        }
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        width="50%"
        style={{
          top: 20,
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
        bodyStyle={{
          padding: "12px",
          borderRadius: "12px",
          backgroundColor: "#fff",
        }}
        className="cvUpdate"
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          // style={{ marginTop: "16px" }}
        >
          {/* Role Name Field */}
          <Form.Item
            name="name"
            label={
              <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                Role Name
              </div>
            }
            rules={[
              { required: true, message: "Role name is required" },
              { max: 20, message: "Role name cannot exceed 20 characters" },
            ]}
          >
            <Input placeholder="Enter Role Name" />
          </Form.Item>

          {/* Permissions Section */}
          <Form.Item
            label={
              <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                Permissions
              </div>
            }
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: "12px",
              }}
            >
              {permissions.map((permission) => (
                <Checkbox
                  key={permission}
                  checked={checkedPermissions.includes(permission)}
                  onChange={() => handleCheck(permission)}
                  style={{ fontWeight: 500, marginLeft: "8px" }}
                >
                  {permission.replace(/_/g, " ")}
                </Checkbox>
              ))}
            </div>
          </Form.Item>

          {/* Submit Button */}
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              type="secondry"
              style={{ padding: "0 24px", marginRight: "10px" }}
              onClick={() => resetCheckboxes()}
            >
              reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ padding: "0 24px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        className="cvUpdate"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Add Role
            </Title>
            <svg
              width="24"
              height="24"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={handleAddCancel}
            >
              <circle cx="19" cy="19" r="19" fill="#F4F4F4" />
              <path
                d="M13.4854 13.4852L25.0001 25"
                stroke="#333"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M25 13.4852L13.4853 25"
                stroke="#333"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        }
        open={showAddModal}
        onCancel={handleAddCancel}
        footer={null}
        width="50%"
        style={{
          top: 20,
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
        bodyStyle={{
          padding: "24px",
          borderRadius: "12px",
          backgroundColor: "#fff",
        }}
      >
        <Form layout="vertical" onFinish={handleAddSubmit}>
          {/* Role Input Field */}
          <Form.Item
            name="role"
            label={
              <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                Role Name
              </div>
            }
            rules={[
              { required: true, message: "Please enter a role name" },
              { max: 20, message: "Role name cannot exceed 20 characters" },
            ]}
          >
            <Input placeholder="Enter Role Name" />
          </Form.Item>

          {/* Permissions Section */}
          <Form.Item
            rules={[{ required: true, message: "Please select permissions" }]}
            label={
              <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                Permissions
              </div>
            }
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: "10px",
              }}
            >
              {permissions.map((permission) => (
                <Checkbox
                  key={permission}
                  onChange={() => handleCheck(permission)}
                  checked={checkedPermissions.includes(permission)}
                  style={{ fontWeight: 500 }}
                >
                  {permission.replace(/__/g, " ")}
                </Checkbox>
              ))}
            </div>
          </Form.Item>

          {/* Submit Button */}
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              type="secondry"
              style={{ padding: "0 24px", marginRight: "10px" }}
              onClick={() => resetCheckboxes()}
            >
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ padding: "0 24px" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Card
        className="User"
        title=<div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "16px",
            borderRadius: "20px 20px 0px 0px",
            margin: "0px , -24px",
          }}
        >
          {/* <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "20px",
                fontWeight: "800",
                color: "#13487D",
              }}
            >
              Role
            </Title>
          </div> */}
          {userRole == "company" && (
            <svg
              width="40"
              height="40"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowAddModal(!showAddModal);
              }}
            >
              <rect width="64" height="64" rx="18" fill="#F4F4F4" />
              <path
                d="M32 22V42"
                stroke="#13487D"
                stroke-width="2.7"
                stroke-linecap="round"
              />
              <path
                d="M42 32L22 32"
                stroke="#13487D"
                stroke-width="2.7"
                stroke-linecap="round"
              />
            </svg>
          )}
        </div>
        bordered={false}
        style={{ borderRadius: "18px", maxWidth: "100%", overflow: "hidden" }}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            ...tableParams.pagination,
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <a>Prev</a>;
              }
              if (type === "next") {
                return <a>Next</a>;
              }
              return originalElement;
            },
          }}
          loading={loading}
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default Roles;
