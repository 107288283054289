import React, { useEffect, useState } from "react";
import { Card, Form, Input, Select, Row, Col, Button, DatePicker } from "antd";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";

const { Option } = Select;

const InvoiceForm = () => {
  const [form] = Form.useForm();
  const [candidateData, setCandidateData] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [backendData, setBackendData] = useState({
    TotalWorkingHour: 0,
    totalBudget: 0,
  });

  const handleCandidateChange = (value) => setSelectedCandidate(value);

  const handleMonthChange = (value) =>
    // console.log(value);
    setSelectedMonth(value);
  const fetchCandidate = async () => {
    try {
      const results = await postReq("vendor/candidate", {
        limit: 10,
        page: 1,
      });
      if (results.status === 1) {
        setCandidateData(results.data.users);
      } else {
        toastNotification(results.message);
      }
    } catch (error) {
      toastNotification(error.message);
    }
  };

  const fetchBackendData = async () => {
    if (selectedCandidate && selectedMonth) {
      try {
        const [monthName, year] = selectedMonth.split(" ");
        const results = await postReq("vendor/invoice-data", {
          userId: selectedCandidate,
          month: monthName,
          year: parseInt(year, 10),
        });
        if (results.status === 1) {
          setBackendData(results.data);
          updateFormValues(
            results.data.TotalWorkingHour,
            results.data.totalBudget
          );
        } else {
          toastNotification(results.message);
          updateFormValues(0, 0);
        }
      } catch (error) {
        toastNotification(error.message);
      }
    }
  };

  const updateFormValues = (totalHours, totalBudget) => {
    const year = new Date().getFullYear();
    const monthIndex =
      new Date(Date.parse(selectedMonth + " 1, " + year)).getMonth() + 1;
    const totalMonthlyDay = new Date(year, monthIndex, 0).getDate();
    const totalMonthlyHours = new Date(year, monthIndex, 0).getDate() * 8;
    const adjustedHours = totalMonthlyHours;
    const ratePerHour = (totalBudget / adjustedHours).toFixed(2);
    const totalAmount = (totalHours * ratePerHour).toFixed(2);
    form.setFieldsValue({
      totalHours,
      totalBudget,
      totalDays: totalMonthlyDay,
      monthlyHours: adjustedHours,
      ratePerHour: ratePerHour,
      totalAmount: totalAmount,
    });
  };
  const handleMonthDayChange = (days, totalBudget, totalHours) => {
    const totalMonthlyHours = parseInt(days) * 8;
    const ratePerHour = (totalBudget / totalMonthlyHours).toFixed(2);
    const totalAmount = (totalHours * ratePerHour).toFixed(2);

    form.setFieldsValue({
      monthlyHours: totalMonthlyHours,
      ratePerHour: ratePerHour,
      totalAmount: totalAmount,
    });
  };
  const handleFormSubmit = async (values) => {
    console.log(values);
    const [monthName, year] = selectedMonth.split(" ");
    const payload = {
      invoiceNumber: values.invoiceNumber,
      gstNumber: values.gstNumber,
      candidateId: selectedCandidate,
      month: monthName,
      year: year,
      totalHours: values.totalHours,
      totalBudget: values.totalBudget,
      totalDays: parseInt(values.totalDays, 10) || 0,
      monthlyHours: parseFloat(values.monthlyHours),
      ratePerHour: parseFloat(values.ratePerHour),
      totalAmount: parseFloat(values.totalAmount),
      extrapayment: parseFloat(values.extrapayment),
    };

    try {
      const response = await postReq("vendor/generate-invoice", payload);
      if (response.status === 1) {
        toastNotification("success", "Invoice created successfully!");
        form.resetFields();
      } else {
        toastNotification(response.message, "error");
      }
    } catch (error) {
      console.error("Error submitting invoice:", error);
      toastNotification("error", "Failed to create invoice.");
    }
  };

  useEffect(() => {
    fetchCandidate();
  }, []);

  useEffect(() => {
    if (selectedCandidate && selectedMonth) fetchBackendData();
  }, [selectedCandidate, selectedMonth]);

  return (
    <Card
      title="Invoice Form"
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={{ holidayHours: 0 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Candidate"
              name="candidate"
              rules={[
                { required: true, message: "Please select a candidate!" },
              ]}
            >
              <Select
                placeholder="Select Candidate"
                onChange={handleCandidateChange}
              >
                {candidateData.map((candidate) => (
                  <Option key={candidate._id} value={candidate._id}>
                    {candidate.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Month"
              name="month"
              rules={[{ required: true, message: "Please select a month!" }]}
            >
              <DatePicker
                picker="month"
                onChange={(date, dateString) => {
                  if (date) {
                    setSelectedMonth(date.format("MMMM YYYY")); // Example: "March 2025"
                  }
                }}
                placeholder="Select Month"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Hours" name="totalHours">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Budget" name="totalBudget">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="GST Number"
              name="gstNumber"
              rules={[{ required: true, message: "Please enter gst number!" }]}
            >
              <Input placeholder="Enter GST Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Invoice Number"
              name="invoiceNumber"
              rules={[
                { required: true, message: "Please enter an invoiceNo.!" },
              ]}
            >
              <Input placeholder="Enter Invoice Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Days" name="totalDays">
              <Input
                onChange={(e) =>
                  handleMonthDayChange(
                    e.target.value,
                    backendData.totalBudget,
                    backendData.TotalWorkingHour
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Monthly Hours" name="monthlyHours">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Rate per Hour" name="ratePerHour">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Amount" name="totalAmount">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Extra Payment" name="extrapayment">
              <Input placeholder="Enter Extra Amount if any." />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "20px" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default InvoiceForm;
