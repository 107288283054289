import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography, Select } from "antd";
import eChart from "./configs/eChart";
import { useEffect, useState } from "react";
const { Option } = Select;

function EChart({ data, dropDown, selectedDropDown, setSelectedDropDown }) {
  // const data = [
  //   { vendorName: "karan", totalApplied: 10 },
  //   { vendorName: "sachin", totalApplied: 12 },
  //   { vendorName: "unnati", totalApplied: 5 },
  //   { vendorName: "vrushali", totalApplied: 7 },
  // ];

  const { Title, Paragraph } = Typography;
  const [eChart, setEChart] = useState({
    series: [{ name: "Sales", data: [], color: "#fff" }],
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "25%", borderRadius: 5 },
      },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 1, colors: ["transparent"] },
      grid: { show: true, borderColor: "#ccc", strokeDashArray: 2 },
      xaxis: {
        categories: [],
        labels: {
          style: { colors: "#fff" },
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: { colors: "#fff" },
        },
      },
      tooltip: { y: { formatter: (val) => `${val}` } },
    },
  });
  const items = [
    {
      Title: "3,6K",
      user: "Users",
    },
    {
      Title: "2m",
      user: "Clicks",
    },
    {
      Title: "$772",
      user: "Sales",
    },
    {
      Title: "82",
      user: "Items",
    },
  ];
  useEffect(() => {
    console.log(data);
    if (data?.length > 0) {
      const categories = data.map((item) =>
        item.vendorName.length > 10
          ? item.vendorName.slice(0, 10) + "..."
          : item.vendorName
      );
      const fullNames = data.map((item) => item.vendorName);
      const newData = data.map((item) => item.totalApplied);

      setEChart((prev) => ({
        ...prev,
        series: [{ ...prev.series[0], data: newData }],
        options: {
          ...prev.options,
          xaxis: {
            ...prev.options.xaxis,
            categories,
            labels: {
              style: { colors: "#fff" },
              rotate: -45, // Rotates text for better visibility
              trim: true, // Trims long labels automatically
            },
          },
          tooltip: {
            enabled: true, // Disable default tooltip for x-axis
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="custom-tooltip" style="background: white; padding: 5px; border-radius: 5px; font-Size:14px">
                        <span>${fullNames[dataPointIndex]}</span><br />
                        ${series[seriesIndex][dataPointIndex]} Applied
                      </div>`;
            },
          },
        },
      }));
    }
  }, [data]);

  // useEffect(() => {
  //   console.log(data);
  //   if (data?.length > 0) {
  //     const categories = data.map((item) => item.vendorName);
  //     const newData = data.map((item) => item.totalApplied);

  //     setEChart((prev) => ({
  //       ...prev,
  //       series: [{ ...prev.series[0], data: newData }],
  //       options: {
  //         ...prev.options,
  //         xaxis: { ...prev.options.xaxis, categories },
  //       },
  //     }));
  //   }
  // }, [data]);
  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Select
          placeholder="Select Requirement"
          value={selectedDropDown?.value}
          onChange={(value) => {
            const selected = dropDown.find((item) => item.value === value);
            setSelectedDropDown(selected);
          }}
          style={{ width: "45%" }}
        >
          {dropDown.map((data, i) => (
            <Option key={i} value={data.value}>
              {data.label}
            </Option>
          ))}
        </Select>
      </div>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={320}
        />
      </div>
      {/* <div className="chart-vistior">
        <Title level={5}>Active Users</Title>
        <Paragraph className="lastweek">
          than last week <span className="bnb2">+30%</span>
        </Paragraph>
        <Paragraph className="lastweek">
          We have created multiple options for you to put together and customise
          into pixel perfect pages.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div> */}
    </>
  );
}

export default EChart;
