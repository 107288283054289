import React, { useEffect, useState } from "react";
import "./setting.css";
import Users from "./Users";
import Roles from "./Roles";

const Setting = () => {
  const [activeTab, setActiveTab] = useState("Users");

  return (
    <div className="container mt-4 trip-container">
      <div className="report-results mt-4">
        {/* <p
          className="mb-3"
          style={{ fontSize: "18px", textAlign: "start", fontWeight: "700" }}
        >
          TRIP LIST
        </p> */}
        <div className="tabs-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="tabs">
              {["Users", "Roles"].map((tab) => (
                <p
                  key={tab}
                  className={`mb-0 tab-item ${
                    activeTab === tab ? "active1" : ""
                  }`}
                  onClick={() => setActiveTab(tab)}
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </p>
              ))}
            </div>

            {/* <div className="ml-auto flex items-center gap-2">
              {!showSearch ? (
                <IoMdSearch
                  size={22}
                  className="cursor-pointer text-gray-500 hover:text-gray-700"
                  onClick={() => setShowSearch(true)}
                />
              ) : (
                <div className="relative">
                  <input
                    ref={inputRef}
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Search city..."
                    className="px-2 py-1 w-full"
                    style={{
                      // border: "none",
                      // background:"#eee"
                      background: "none",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#333",
                      borderRadius: "12px",
                    }}
                  />
                  <IoMdClose
                    size={22}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-700"
                    onClick={() => {
                      setCity("");
                      setShowSearch(false);
                    }}
                  />
                </div>
              )}
            </div> */}
          </div>
          <hr className="divider" />
        </div>
        {activeTab === "Users" ? <Users /> : <Roles />}
      </div>
    </div>
  );
};

export default Setting;
