import React, { useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Card,
  Typography,
  Row,
  Col,
  TimePicker,
  message,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Title } = Typography;

const TaskForm = ({ record }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([
    { id: 1, taskName: "", taskDesc: "", startTime: null, endTime: null },
  ]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setTasks(
      tasks.map((task) => ({ ...task, startTime: null, endTime: null }))
    ); // Reset time on date change
  };

  const handleAddTask = () => {
    setTasks([
      ...tasks,
      {
        id: tasks.length + 1,
        taskName: "",
        taskDesc: "",
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const handleRemoveTask = (id) => {
    if (tasks.length === 1) {
      message.warning("At least one task must be present.");
      return;
    }
    setTasks(tasks.filter((task) => task.id !== id));
  };

  const handleTaskChange = (id, key, value) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === id) {
        if (!selectedDate) {
          message.error("Please select a date first.");
          return task;
        }

        if (key == "startTime" || key == "endTime") {
          const newDateTime = value
            ? dayjs(selectedDate).hour(value.hour()).minute(value.minute())
            : null;
          if (
            key === "startTime" &&
            task.endTime &&
            newDateTime.isAfter(task.endTime)
          ) {
            message.error("Start time cannot be after the end time.");
            return task;
          }
          if (
            key === "endTime" &&
            task.startTime &&
            newDateTime.isBefore(task.startTime)
          ) {
            message.error("End time cannot be before the start time.");
            return task;
          }

          return { ...task, [key]: newDateTime };
        }

        return { ...task, [key]: value };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleFinish = async (values) => {
    try {
      setLoading(true);

      const payload = {
        date: values.date ? values.date.toISOString() : null,
        projectName: values.projectName,
        tasks: tasks.map((task) => ({
          taskName: task.taskName,
          taskDesc: task.taskDesc,
          startTime: task.startTime
            ? dayjs(task.startTime).toISOString()
            : null,
          endTime: task.endTime ? dayjs(task.endTime).toISOString() : null,
        })),
      };

      const response = await postReq("/company/create-timesheet", payload);
      if (response.status == 1) {
        toastNotification("success", response.message);
        history.push("/company/TimeSheet");
      } else {
        toastNotification("error", response.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title={
        <Title
          level={4}
          style={{ marginBottom: 0, fontWeight: "bold", color: "#13487D" }}
        >
          Add Tasks
        </Title>
      }
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Select Date"
              name="date"
              rules={[{ required: true, message: "Please select a date." }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(current) => current && current > dayjs()}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project Name"
              name="projectName"
              rules={[
                { required: true, message: "Please enter the project name." },
              ]}
            >
              <Input placeholder="Enter Project Name" />
            </Form.Item>
          </Col>
        </Row>

        {tasks.map((task, index) => (
          <Card
            key={task.id}
            style={{
              marginBottom: 16,
              border: "1px solid #d9d9d9",
              borderRadius: 8,
            }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={`Task ${index + 1}`}
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the task name.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Task Name"
                    value={task.taskName}
                    onChange={(e) =>
                      handleTaskChange(task.id, "taskName", e.target.value)
                    }
                  />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Start Time" required>
                      <TimePicker
                        format="HH:mm"
                        style={{ width: "100%" }}
                        value={task.startTime ? dayjs(task.startTime) : null}
                        onChange={(value) =>
                          handleTaskChange(task.id, "startTime", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="End Time" required>
                      <TimePicker
                        format="HH:mm"
                        style={{ width: "100%" }}
                        value={task.endTime ? dayjs(task.endTime) : null}
                        onChange={(value) =>
                          handleTaskChange(task.id, "endTime", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Task Description"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the project name.",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter Task Description"
                    value={task.taskDesc}
                    autoSize={{ minRows: 5, maxRows: 6 }}
                    onChange={(e) =>
                      handleTaskChange(task.id, "taskDesc", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ textAlign: "right" }}>
                <Button
                  type="danger"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveTask(task.id)}
                >
                  Remove Task
                </Button>
              </Col>
            </Row>
          </Card>
        ))}

        <div
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={handleAddTask}
            style={{ marginTop: 16 }}
          >
            Add Task
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ marginTop: 16 }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TaskForm;
