import ReactApexChart from "react-apexcharts";
import { Select, Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
const { Option } = Select;

function LineChart({ data, dropDown, selectedDropDown, setSelectedDropDown }) {
  const { Title, Paragraph } = Typography;
  const [lineChart, setLineChart] = useState({
    series: [
      {
        name: "Total Applied",
        data: [0],
        offsetY: 0,
      },
      {
        name: "Cv Selected",
        data: [0],
        offsetY: 0,
      },
      {
        name: "Profile Selected",
        data: [0],
        offsetY: 0,
      },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
        categories: [],
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });
  useEffect(() => {
    if (data?.length > 0) {
      const categories = data.map((item) =>
        item.vendorName.length > 10
          ? item.vendorName.slice(0, 10) + "..."
          : item.vendorName
      );
      const fullNames = data.map((item) => item.vendorName);
      const totalApplied = data.map((item) => item.totalApplied);
      const newData = data.map((item) => item.totalCvSelected);
      const newData1 = data.map((item) => item.totalProfileSelected);

      setLineChart((prev) => ({
        ...prev,
        series: [
          { ...prev.series[0], data: totalApplied },
          { ...prev.series[1], data: newData },
          { ...prev.series[2], data: newData1 },
        ],
        options: {
          ...prev.options,
          xaxis: {
            ...prev.options.xaxis,
            categories,
            labels: {
              style: {
                fontSize: "12px",
                fontWeight: 600,
                colors: ["#8c8c8c"],
              },
              rotate: -45, // Rotate labels to prevent overlap
              trim: true,
            },
            tooltip: {
              enabled: false, // Hide default x-axis tooltip
            },
          },
          tooltip: {
            enabled: true,
            shared: true,
            custom: function ({ series, dataPointIndex, w }) {
              return `<div style="background: white; padding: 10px; border-radius: 5px; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);">
                        <strong style="color: black;">${fullNames[dataPointIndex]}</strong><br />
                        <span style="color: ${w.globals.colors[0]}; font-weight: bold;">● </span><span>Total Applied: ${series[0][dataPointIndex]}</span><br />
                        <span style="color: ${w.globals.colors[1]}; font-weight: bold;">● </span><span>CV Selected: ${series[1][dataPointIndex]}</span><br />
                        <span style="color: ${w.globals.colors[2]}; font-weight: bold;">● </span><span>Profile Selected: ${series[2][dataPointIndex]}</span>
                      </div>`;
            },
          },
        },
      }));
    } else {
      setLineChart({
        series: [
          {
            name: "Total Applied",
            data: [0],
            offsetY: 0,
          },
          {
            name: "Cv Selected",
            data: [0],
            offsetY: 0,
          },
          {
            name: "Profile Selected",
            data: [0],
            offsetY: 0,
          },
        ],

        options: {
          chart: {
            width: "100%",
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },

          legend: {
            show: false,
          },

          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },

          yaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#8c8c8c"],
              },
            },
          },

          xaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#8c8c8c"],
              },
            },
            categories: [],
          },

          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      });
    }
  }, [data]);
  return (
    <>
      {/* <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Select
          placeholder="Select Requirement"
          value={selectedDropDown?.value}
          onChange={(value) => {
            const selected = dropDown.find((item) => item.value === value);
            setSelectedDropDown(selected);
          }}
          style={{ width: "40%" }}
        >
          {dropDown.map((data, i) => (
            <Option key={i} value={data.value}>
              {data.label}
            </Option>
          ))}
        </Select>
      </div> */}
      <div className="linechart">
        <div
          style={{
            width: "300px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {/* <Title level={5}>Selection Graph</Title> */}
          <Select
            placeholder="Select Requirement"
            value={selectedDropDown?.value}
            onChange={(value) => {
              const selected = dropDown.find((item) => item.value === value);
              setSelectedDropDown(selected);
            }}
            style={{ width: "80%" }}
          >
            {dropDown.map((data, i) => (
              <Option key={i} value={data.value}>
                {data.label}
              </Option>
            ))}
          </Select>
          {/* <Paragraph className="lastweek">
            than last week <span className="bnb2">+30%</span>
          </Paragraph> */}
        </div>
        <div className="sales">
          <ul style={{ display: "flex", gap: "10px" }}>
            <li>{<MinusOutlined />} Applied</li>
            <li>{<MinusOutlined />} Cv </li>
            <li>{<MinusOutlined />} Profile </li>
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
