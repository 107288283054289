import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Card,
  Tooltip,
  Modal,
  Form,
  Input,
  Select,
  Button,
  Typography,
  message,
} from "antd";
import { getReq, postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";
import Swal from "sweetalert2";
const { Option } = Select;
const Users = () => {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [roles, setRole] = useState({ data: [] });
  const [skills, setSkills] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "MobileNo",
      dataIndex: "mobileNo",
      key: "mobileNo",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => <p>{role?.name}</p>,
    },
    {
      title: "Skills",
      key: "skills",
      dataIndex: "skills",
      render: (_, { skills }) => {
        const displayedPermissions = skills.slice(0, 4); // First four elements
        const remaingPermission = skills.slice(4, skills.length);
        const remainingCount = skills.length - 4; // Remaining count

        return (
          <>
            {displayedPermissions.map((tag, i) => (
              <span
                key={tag}
                style={{ display: "inline-block", marginRight: 6 }}
              >
                <p>
                  {tag.value.toUpperCase()}
                  {i < displayedPermissions.length - 1 && ","}
                </p>
              </span>
            ))}
            {remainingCount > 0 && (
              <Tooltip
                placement="top"
                title={
                  <div
                    style={{
                      gap: "4px",
                      maxWidth: "200px",
                      wordWrap: "break-word",
                      overflow: "hidden",
                    }}
                  >
                    {remaingPermission.map((tag) => (
                      <span key={tag} style={{ whiteSpace: "nowrap" }}>
                        <p
                          style={{
                            fontSize: "12px",
                            margin: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tag.value.toUpperCase()}
                        </p>
                      </span>
                    ))}
                  </div>
                }
              >
                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 4,
                    height: "20px",
                    width: "40px",
                    borderRadius: "10px",
                    background: "#F8F8F8",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  <p style={{ margin: 0, fontWeight: "bold" }}>
                    +{remainingCount}
                  </p>
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div style={{ cursor: "pointer" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="22"
              height="22"
              onClick={() => {
                handleChangePassword(record._id);
              }}
            >
              <path
                fill="#50C878"
                d="m15.061,19.061l-.935.935.935.935c.586.586.586,1.536,0,2.121-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439l-.935-.935-.935.935c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439c-.586-.585-.586-1.535,0-2.121l.935-.935-.935-.935c-.586-.586-.586-1.536,0-2.121.586-.586,1.535-.586,2.121,0l.935.935.935-.935c.586-.586,1.535-.586,2.121,0,.586.585.586,1.535,0,2.121Zm7.565.935l.935-.935c.586-.586.586-1.536,0-2.121-.586-.586-1.535-.586-2.121,0l-.935.935-.935-.935c-.586-.586-1.535-.586-2.121,0-.586.585-.586,1.535,0,2.121l.935.935-.935.935c-.586.586-.586,1.536,0,2.121.293.293.677.439,1.061.439s.768-.146,1.061-.439l.935-.935.935.935c.293.293.677.439,1.061.439s.768-.146,1.061-.439c.586-.585.586-1.535,0-2.121l-.935-.935Zm-16.065-3.056c-.586-.586-1.535-.586-2.121,0l-.935.935-.935-.935c-.586-.586-1.535-.586-2.121,0-.586.585-.586,1.535,0,2.121l.935.935-.935.935c-.586.586-.586,1.536,0,2.121.293.293.677.439,1.061.439s.768-.146,1.061-.439l.935-.935.935.935c.293.293.677.439,1.061.439s.768-.146,1.061-.439c.586-.585.586-1.535,0-2.121l-.935-.935.935-.935c.586-.586.586-1.536,0-2.121Zm10.939-4.939c0,1.657-1.343,3-3,3h-5c-1.657,0-3-1.343-3-3v-3c0-.885.39-1.672,1-2.221v-2.279c0-2.481,2.019-4.5,4.5-4.5s4.5,2.019,4.5,4.5v2.279c.61.549,1,1.336,1,2.221v3Zm-7-7.5v1.5h3v-1.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5,1.5Zm3,6c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5,1.5.672,1.5,1.5,1.5,1.5-.672,1.5-1.5Z"
              />
            </svg>
          </div>
          <div style={{ cursor: "pointer" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                handleEditClick(record);
              }}
            >
              <path
                d="M16.625 13.608L11.6667 18.5663C11.2315 19.0003 10.6307 19.25 10.017 19.25H9.33333V18.5663C9.33333 17.9433 9.576 17.3577 10.017 16.9167L14.9753 11.9583L16.625 13.608ZM17.8418 9.09183L16.625 10.3087L18.2747 11.9583L19.4915 10.7415C19.9465 10.2865 19.9465 9.54683 19.4915 9.09183C19.0505 8.64967 18.2817 8.64967 17.8418 9.09183ZM28 5.83333V22.1667C28 25.3832 25.3832 28 22.1667 28H5.83333C2.61683 28 0 25.3832 0 22.1667V5.83333C0 2.61683 2.61683 0 5.83333 0H22.1667C25.3832 0 28 2.61683 28 5.83333ZM21.1423 7.44217C19.8193 6.12033 17.514 6.12033 16.1922 7.44217L8.36733 15.267C7.4865 16.1478 7 17.3203 7 18.5663V20.4167C7 21.0618 7.52267 21.5833 8.16667 21.5833H10.017C11.263 21.5833 12.4355 21.098 13.3163 20.216L21.1412 12.3912C22.5062 11.0262 22.5073 8.80717 21.1423 7.44217Z"
                fill="#666666"
              />
            </svg>
          </div>
          <div style={{ cursor: "pointer" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => deleteUser(record._id)}
            >
              <path
                d="M9.36304 20.4881C9.36304 20.5674 9.39448 20.6434 9.45046 20.6995C9.50644 20.7555 9.58239 20.7871 9.66163 20.7873H18.3383C18.4176 20.7871 18.4935 20.7555 18.5495 20.6995C18.6055 20.6434 18.6369 20.5674 18.6369 20.4881V10.6952H9.36304V20.4881ZM14.7913 12.8576C14.788 12.7051 14.8151 12.5534 14.8712 12.4115C14.9273 12.2696 15.0111 12.1404 15.1178 12.0313C15.2245 11.9223 15.3519 11.8356 15.4925 11.7765C15.6332 11.7173 15.7842 11.6868 15.9367 11.6868C16.0893 11.6868 16.2403 11.7173 16.381 11.7765C16.5216 11.8356 16.649 11.9223 16.7557 12.0313C16.8624 12.1404 16.9462 12.2696 17.0023 12.4115C17.0583 12.5534 17.0855 12.7051 17.0822 12.8576V18.4455C17.0855 18.5981 17.0583 18.7497 17.0023 18.8916C16.9462 19.0335 16.8624 19.1628 16.7557 19.2718C16.649 19.3809 16.5216 19.4675 16.381 19.5267C16.2403 19.5858 16.0893 19.6163 15.9367 19.6163C15.7842 19.6163 15.6332 19.5858 15.4925 19.5267C15.3519 19.4675 15.2245 19.3809 15.1178 19.2718C15.0111 19.1628 14.9273 19.0335 14.8712 18.8916C14.8151 18.7497 14.788 18.5981 14.7913 18.4455V12.8576ZM10.9178 12.8576C10.9145 12.7051 10.9416 12.5534 10.9977 12.4115C11.0538 12.2696 11.1376 12.1404 11.2443 12.0313C11.351 11.9223 11.4784 11.8356 11.619 11.7765C11.7596 11.7173 11.9107 11.6868 12.0632 11.6868C12.2158 11.6868 12.3668 11.7173 12.5074 11.7765C12.6481 11.8356 12.7755 11.9223 12.8822 12.0313C12.9889 12.1404 13.0727 12.2696 13.1288 12.4115C13.1848 12.5534 13.212 12.7051 13.2087 12.8576V18.4455C13.212 18.5981 13.1848 18.7497 13.1288 18.8916C13.0727 19.0335 12.9889 19.1628 12.8822 19.2718C12.7755 19.3809 12.6481 19.4675 12.5074 19.5267C12.3668 19.5858 12.2158 19.6163 12.0632 19.6163C11.9107 19.6163 11.7596 19.5858 11.619 19.5267C11.4784 19.4675 11.351 19.3809 11.2443 19.2718C11.1376 19.1628 11.0538 19.0335 10.9977 18.8916C10.9416 18.7497 10.9145 18.5981 10.9178 18.4455V12.8576Z"
                fill="#F44336"
              />
              <path
                d="M12.6564 7.21274H15.3432V8.40438H12.6564V7.21274Z"
                fill="#F44336"
              />
              <path
                d="M21.4003 0H6.59969C4.84934 0 3.17069 0.695322 1.933 1.933C0.695322 3.17069 0 4.84934 0 6.59969L0 21.4003C0 23.1507 0.695322 24.8293 1.933 26.067C3.17069 27.3047 4.84934 28 6.59969 28H21.4003C23.1507 28 24.8293 27.3047 26.067 26.067C27.3047 24.8293 28 23.1507 28 21.4003V6.59969C28 4.84934 27.3047 3.17069 26.067 1.933C24.8293 0.695322 23.1507 0 21.4003 0ZM21.537 10.6952H20.9278V20.4881C20.9272 21.1748 20.6542 21.8331 20.1688 22.3187C19.6833 22.8043 19.025 23.0774 18.3384 23.0781H9.66164C8.975 23.0774 8.3167 22.8043 7.83123 22.3187C7.34575 21.8331 7.07277 21.1748 7.07219 20.4881V10.6952H6.46297C6.31044 10.6986 6.15879 10.6714 6.0169 10.6153C5.87501 10.5593 5.74575 10.4754 5.6367 10.3687C5.52765 10.262 5.44101 10.1346 5.38186 9.99402C5.32271 9.85339 5.29224 9.70237 5.29224 9.5498C5.29224 9.39724 5.32271 9.24622 5.38186 9.10559C5.44101 8.96496 5.52765 8.83756 5.6367 8.73087C5.74575 8.62418 5.87501 8.54034 6.0169 8.48427C6.15879 8.42821 6.31044 8.40104 6.46297 8.40438H10.3655V6.07031C10.3655 5.76573 10.4865 5.47362 10.7018 5.25824C10.9172 5.04287 11.2093 4.92188 11.5139 4.92188H16.4905C16.7946 4.9226 17.086 5.04342 17.3011 5.25824C17.5162 5.47306 17.6372 5.76573 17.6372 6.07031V8.40438H21.5397C21.6922 8.40104 21.8439 8.42821 21.9857 8.48427C22.1276 8.54034 22.2569 8.62418 22.3659 8.73087C22.4749 8.83756 22.5616 8.96496 22.6208 9.10559C22.6799 9.24622 22.7104 9.39724 22.7104 9.5498C22.7104 9.70237 22.6799 9.85339 22.6208 9.99402C22.5616 10.1346 22.4749 10.262 22.3659 10.3687C22.2569 10.4754 22.1276 10.5593 21.9857 10.6153C21.8439 10.6714 21.6922 10.6986 21.5397 10.6952H21.537Z"
                fill="#F44336"
              />
            </svg>
          </div>
        </Space>
      ),
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [form] = Form.useForm();
  const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    // Reset the form fields when the modal opens for editing a user
    if (visible) {
      form.resetFields(); // Clear the form on modal close
    }
  }, [visible, form]);
  useEffect(() => {
    if (editData.name && editData.email && editData.mobileNo && editData.role) {
      form.setFieldsValue({
        name: editData.name,
        email: editData.email,
        mobileNo: editData.mobileNo,
        roleId: editData.role._id,
        skills: editData.skills.map((data) => data._id),
      });
    }
  }, [editData, form]);

  const handleChangePassword = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Password Change?",
        text: "You sure want chnage the password of this user!, user will get the password on his email.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      });
      if (result.isConfirmed) {
        const response = await postReq(`/company/reset-pass/${id}`, {});
        if (response.status === 200) {
          toastNotification(
            "success",
            response.message || "password generate successfully!"
          );
        } else {
          toastNotification("error", response.message || "server side error!");
        }
      }
    } catch (error) {
      toastNotification("error", error.message || "error generating password");
    }
  };
  const handleEditClick = (record) => {
    setEditData(record);
    form.setFieldsValue({
      name: record.name,
      email: record.email,
      mobileNo: record.mobileNo,
      roleId: record?.role?._id,
      skills: record.skills.map((data) => data._id),
    });
    setVisible(true);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const roles = await getReq("/company/roles");
      const skills = await postReq("/common/getTags", {
        type: "requiredTech",
        companyId: `${companyDetails._id}`,
      });
      const results = await postReq("/company/users", tableParams);
      setRole(roles);
      setSkills(skills.data);
      setData(results.data.users);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: results.data.totalCount,
        },
      });
    } catch (error) {
      toastNotification("error", error.message);
    }
  };
  useEffect(fetchData, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleCancel = async () => {
    setVisible(!visible);
    setEditData({});
  };
  const handleAddCancel = async () => {
    setShowAddModal(!showAddModal);
    setEditData({});
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const updateRole = await postReq("/company/update-user", {
        ...values,
        _id: editData._id,
      });
      if (updateRole.status == 1) {
        toastNotification("success", updateRole.message);
        setTimeout(() => {
          form.setFieldsValue({});
          setEditData({});
          fetchData();
          handleCancel();
        }, 2000);
      } else {
        toastNotification("error", updateRole.message);
        setTimeout(() => {
          form.setFieldsValue({});
          setEditData({});
          handleCancel();
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      setEditData({});
      console.log("error", error.message);
    }
    setVisible(false);
  };

  const handleAddSubmit = async (values) => {
    try {
      setLoading(true);
      const addUser = await postReq("/company/create-user", { ...values });
      if (addUser.status == 1) {
        toastNotification("success", addUser.message);
        setTimeout(() => {
          form.setFieldsValue({});
          fetchData();
          setShowAddModal(false);
          setLoading(false);
        }, 2000);
      } else {
        toastNotification("error", addUser.message);
        setTimeout(() => {
          form.setFieldsValue({});
          setShowAddModal(false);
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
    setShowAddModal(false);
  };

  const deleteUser = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You sure you want delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const deleteUserApi = await postReq("/company/delete-user", {
          _id: id,
        });
        if (deleteUserApi.status == 1) {
          toastNotification("success", deleteUserApi.message);
          fetchData();
        } else {
          toastNotification("error", deleteUserApi.message);
          fetchData();
        }
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  };
  return (
    <>
      <Modal
        title="Update User"
        open={visible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        style={{ borderRadius: "20px", overflow: "hidden" }}
        bodyStyle={{ padding: 20, borderRadius: "20px" }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Role name is required" },
              { max: 20, message: "Role name cannot exceed 20 characters" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { max: 40, message: "Email name cannot exceed 40 characters" },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mobile No"
            name="mobileNo"
            rules={[
              { required: true, message: "Role name is required" },
              { max: 10, message: "Mobile Number cannot exceed 10 Number" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="roleId"
            label="Role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Select placeholder="Select a role">
              {roles.data.map((role) => (
                <Option key={role._id} value={role._id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item
            name="skills"
            label="Skills"
            rules={[
              { required: true, message: "Please select at least one skill" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select Skills"
              loading={loading}
              style={{ width: "100%" }}
            >
              {skills.map((skill) => (
                <Option key={skill._id} value={skill._id}>
                  {skill.value}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="skills"
            label="Skills"
            rules={[
              { required: true, message: "Please select at least one skill" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select Skills"
              loading={loading}
              style={{ width: "100%" }}
              maxTagCount={3}
              onChange={(selectedValues) => {
                if (selectedValues.length > 3) {
                  message.warning("You can only select up to 3 skills.");
                  selectedValues.pop();
                  return; // Prevent further updates to the value
                }
                form.setFieldsValue({ skills: selectedValues }); // Update the form field value
              }}
            >
              {skills.map((skill) => (
                <Option key={skill._id} value={skill._id}>
                  {skill.value}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ float: "left" }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update User"
        open={showAddModal}
        onCancel={handleAddCancel}
        footer={null}
        width={600}
        style={{ borderRadius: "20px", overflow: "hidden" }}
        bodyStyle={{ padding: 20, borderRadius: "20px" }}
      >
        <Form layout="vertical" onFinish={handleAddSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Role name is required" },
              { max: 20, message: "Role name cannot exceed 20 characters" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { max: 40, message: "Email name cannot exceed 40 characters" },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mobile No"
            name="mobileNo"
            rules={[
              { required: true, message: "Role name is required" },
              { max: 10, message: "Mobile Number cannot exceed 10 Number" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="roleId"
            label="Role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Select placeholder="Select a role">
              {roles.data.map((role) => (
                <Option key={role._id} value={role._id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="skills"
            label="Skills"
            rules={[
              { required: true, message: "Please select at least one skill" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select Skills"
              loading={loading}
              style={{ width: "100%" }}
              maxTagCount={3}
              onChange={(selectedValues) => {
                if (selectedValues.length > 3) {
                  selectedValues.pop();
                  message.warning("You can only select up to 3 skills.");
                }
                form.setFieldsValue({ skills: selectedValues });
              }}
            >
              {skills.map((skill) => (
                <Option key={skill._id} value={skill._id}>
                  {skill.value}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ float: "left" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Card
        className="User"
        title=<div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",

            padding: "16px",
            borderRadius: "20px 20px 0px 0px",
            margin: "0px , -24px",
          }}
        >
          {/* <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "20px",
                fontWeight: "800",
                color: "#13487D",
              }}
            >
              Users
            </Title>
          </div> */}

          <svg
            width="40"
            height="40"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowAddModal(!showAddModal);
            }}
          >
            <rect width="64" height="64" rx="18" fill="#F4F4F4" />
            <path
              d="M32 22V42"
              stroke="#13487D"
              stroke-width="2.7"
              stroke-linecap="round"
            />
            <path
              d="M42 32L22 32"
              stroke="#13487D"
              stroke-width="2.7"
              stroke-linecap="round"
            />
          </svg>
        </div>
        bordered={false}
        style={{ borderRadius: "18px", maxWidth: "100%", overflow: "hidden" }}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            ...tableParams.pagination,
            itemRender: (current, type, originalElement) => {
              if (type === "prev") {
                return <a>Prev</a>;
              }
              if (type === "next") {
                return <a>Next</a>;
              }
              return originalElement;
            },
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default Users;
