import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input, Radio } from "antd";
import { toastNotification } from "../utils/toasthelper";
import { postReq } from "../api";
import OnBoardPage from "./Vendor/onBoardPage";
import UploadDocsForm from "./Vendor/uploadDoc";
import CompanyRegistration from "./companyRegistration";
import CandidateForm from "./candidateOnBoard";
import signinbg from "../assets/images/img-signin.jpg";
import auth_screen from "../assets/images/auth-screens.png";
import custom_1 from "../assets/images/custom-1.png";
const { Title, Text } = Typography;
const { Content } = Layout;

const NewSignIn = () => {
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState("");
  const [vendorId, setVendorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Extract vendor ID and determine the current page
  useEffect(() => {
    const path = location.pathname;

    if (path.includes("/auth/company/registration")) {
      setCurrentPage("companyRegistration");
    } else if (path.includes("/auth/company/login")) {
      setCurrentPage("companyLogin");
    } else if (path.includes("/auth/company/user/login")) {
      setCurrentPage("companyUserLogin");
    } else if (path.includes("/auth/vendor/login")) {
      setCurrentPage("vendorLogin");
    } else if (path.includes("/auth/vendor-onboarding")) {
      setCurrentPage("vendorOnboard");

      // Extract the vendor ID from the URL
      const segments = path.split("/");
      const idIndex = segments.findIndex(
        (segment) => segment === "vendor-onboarding"
      );
      if (idIndex !== -1 && segments[idIndex + 1]) {
        setVendorId(segments[idIndex + 1]);
      }
    } else if (path.includes("/auth/upload-signed-doc")) {
      setCurrentPage("uploadSignDoc");
    } else if (path.includes("/auth/candidate/create")) {
      setCurrentPage("candidateOnBoard");
    } else if (path.includes("/company/")) {
      setCurrentPage("companyLogin");
    } else if (path.includes("/vendor/")) {
      setCurrentPage("vendorLogin");
    } else if (path.includes("/")) {
      setCurrentPage("companyLogin");
    }
  }, [location.pathname]);
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const endpoint =
        currentPage === "companyLogin"
          ? "/company/login"
          : currentPage === "vendorLogin"
          ? "/vendor/login"
          : currentPage === "companyUserLogin"
          ? "/company/user/login"
          : null;

      const login = await postReq(endpoint, values);

      if (login.status === 1) {
        toastNotification("success", login.message);
        const { userData, token, roleId } = login.data;
        if (currentPage === "companyLogin") {
          localStorage.setItem("userRole", "company");
          localStorage.setItem("companyToken", token);
          localStorage.setItem("companyDetails", JSON.stringify(userData));
          window.location.replace("/company/dashboard");
        } else if (currentPage === "vendorLogin") {
          localStorage.setItem("userRole", "vendor");
          localStorage.setItem("vendorToken", token);
          localStorage.setItem("vendorDetails", JSON.stringify(userData));
          window.location.replace("/vendor/dashboard");
        } else if (currentPage === "companyUserLogin") {
          localStorage.setItem("userDetails", JSON.stringify(login.data));
          localStorage.setItem("userToken", login.data.token);
          localStorage.setItem("userRole", login?.data?.roleId.slug);
          window.location.replace("/company/TimeSheet");
        }
      } else {
        toastNotification("error", login.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginTypeChange = (e) => {
    const value = e.target.value;

    if (value === "companyLogin") {
      setCurrentPage("companyLogin");
      history.push("/auth/company/login");
    } else if (value === "vendorLogin") {
      setCurrentPage("vendorLogin");
      history.push("/auth/vendor/login");
    } else if (value === "userLogin") {
      setCurrentPage("companyUserLogin");
      history.push("/auth/company/user/login");
    }
  };

  return (
    <Layout
      className="layout-default layout-signin"
      style={{ minHeight: "100vh" }}
    >
      <Content className="signin">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "24px", // Spacing between the side-by-side components
          }}
        >
          {/* Left Section */}
          <div
            style={{
              flex: "1",
              padding: "12px",
              background: "blue",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "50%",
              minHeight: "100vh",
            }}
          >
            <a
              href="/metronic8/demo1/index.html"
              style={{
                marginBottom: "3rem",
              }}
            >
              <img alt="Logo" src={custom_1} style={{ height: "75px" }} />
            </a>

            <img
              className="d-none d-lg-block"
              src={auth_screen}
              alt=""
              style={{
                display: "block",
                maxWidth: "500px",
                marginBottom: "2.5rem",
              }}
            />

            <Title
              level={1}
              style={{
                display: "none",
                color: "white",
                fontWeight: "bolder",
                textAlign: "center",
                marginBottom: "1.75rem",
              }}
              className="d-lg-block"
            >
              Fast, Efficient and Productive
            </Title>

            <Text
              style={{
                display: "none",
                color: "white",
                fontSize: "1rem",
                textAlign: "center",
              }}
              className="d-lg-block"
            >
              In this kind of post,{" "}
              <a
                href="#"
                style={{
                  opacity: 0.75,
                  color: "#ffc107",
                  fontWeight: "bold",
                }}
              >
                the blogger
              </a>{" "}
              introduces a person they’ve interviewed <br /> and provides some
              background information about{" "}
              <a
                href="#"
                style={{
                  opacity: 0.75,
                  color: "#ffc107",
                  fontWeight: "bold",
                }}
              >
                the interviewee
              </a>{" "}
              and their <br /> work following this is a transcript of the
              interview.
            </Text>
          </div>

          {/* Right Section */}
          <div
            style={{
              flex: "1",
              paddingTop: "50px",
              maxWidth: "50%",
              maxHeight: "100vh",
              overflowX: "auto",
            }}
          >
            {currentPage == "companyLogin" ||
            currentPage == "vendorLogin" ||
            currentPage == "companyUserLogin" ? (
              <>
                <Title
                  className="mb-15"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  Sign In
                </Title>
                <Title
                  className="font-regular text-muted"
                  level={5}
                  style={{ textAlign: "center" }}
                >
                  Enter email and password to signin
                </Title>
              </>
            ) : null}

            {currentPage === "companyRegistration" && (
              <>
                <Title
                  level={2}
                  className="mb-15"
                  style={{ textAlign: "center" }}
                >
                  Company Registration
                </Title>
                <Title
                  className="font-regular text-muted"
                  level={5}
                  style={{ textAlign: "center" }}
                >
                  Enter company details to sign up
                </Title>
              </>
            )}

            {currentPage === "vendorOnboard" && (
              <>
                <Title
                  className="mb-15"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  Vendor Onboard
                </Title>
                <Title
                  className="font-regular text-muted"
                  level={5}
                  style={{ textAlign: "center" }}
                >
                  Enter company details to sign up
                </Title>
              </>
            )}

            {/* Dynamic Page Rendering */}
            {currentPage === "companyRegistration" && <CompanyRegistration />}
            {currentPage === "vendorOnboard" && (
              <OnBoardPage vendorId={vendorId} />
            )}
            {currentPage === "uploadSignDoc" && <UploadDocsForm />}
            {currentPage === "candidateOnBoard" && <CandidateForm />}
            {(currentPage === "companyLogin" ||
              currentPage === "vendorLogin" ||
              currentPage === "companyUserLogin") && (
              <Form
                form={form}
                onFinish={handleLogin}
                layout="vertical"
                className="row-col"
                style={{ maxWidth: "380px", margin: "0 auto" }}
              >
                <Form.Item
                  name="loginType"
                  initialValue="companyLogin"
                  rules={[
                    { required: true, message: "Please select a login type!" },
                  ]}
                  style={{ paddingBottom: "20px" }}
                >
                  <Radio.Group onChange={handleLoginTypeChange}>
                    <Radio value="companyLogin">Company Login</Radio>
                    <Radio value="vendorLogin">Vendor Login</Radio>
                    <Radio value="userLogin">User Login</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please enter your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email format",
                    },
                  ]}
                  // style={{ paddingBottom: "10px" }}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  // style={{ paddingBottom: "20px" }}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{}}
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default NewSignIn;
